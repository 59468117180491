import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Legend, LineSeries, ScatterSeries } from '@devexpress/dx-react-chart-material-ui';
import { symbol, symbolCircle } from 'd3-shape';

function legendStyles() {
  return {
    root: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'row',
    },
  } as any;
}

function legendLabelStyles(theme: Theme){
  return {
    label: {
      paddingTop: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
  } as any;
}

function legendItemStyles() {
  return {
    item: {
      flexDirection: 'column',
    },
  } as any;
}

const legendRootBase = ({ classes, ...restProps }: any) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }: any) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }: any) => (
  <Legend.Item className={classes.item} {...restProps} />
);

export const Root = withStyles(legendStyles, { name: "LegendRoot" })(
  legendRootBase,
);

export const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase,
);

export const Item = withStyles(legendItemStyles, { name: "LegendItem" })(
  legendItemBase,
);

const Point = (type: any, pointStyles: any) => (props: any) => {
  const { arg, val, color } = props;
  return (
    <path
      fill={color}
      transform={`translate(${arg} ${val})`}
      d={symbol()
        .size([20] as any as number)
        .type(type)()!}
      style={pointStyles}
    />
  );
};

const DotPoint = Point(symbolCircle, {
  strokeWidth: "0px"
});

export const LineWithDotPoint = (props: any) => (
  <React.Fragment>
    <LineSeries.Path {...props} />
    <ScatterSeries.Path {...props} pointComponent={DotPoint} />
  </React.Fragment>
);
