import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    box: {
      padding: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <Typography className={classes.title} variant="h4">
            About
          </Typography>
          <ul>
            <li><Typography paragraph><b>Project Directors:</b> Christopher Nixon Cox and James Arnold</Typography></li>
            <li><Typography paragraph><b>Model Development by Intellegens Limited:</b> Dr. Gareth Conduit, Royal Society University Research Fellow, Theory of Condensed Matter Group, Cavendish Laboratory, Cambridge University &amp; co-founder, Intellegens Limited and Pavao Santak, Theory of Condensed Matter Group, Cavendish Laboratory, Cambridge University &amp; Intellegens Limited</Typography></li>
            <li><Typography paragraph><b>Data Development:</b> Dr. Pedro da Rocha Pinto, Department of Computing, Imperial College</Typography></li>
            <li><Typography paragraph><b>Development Coordinator:</b> Dr. Julia Arnold</Typography></li>
            <li><Typography paragraph><b>Project Funding:</b> Her Majesty's Government (Innovate UK), James Arnold &amp; Intellegens Limited</Typography></li>
          </ul>
        </Box>
      </Paper>
    </div>
  );
}
