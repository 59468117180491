import React, { useState, ChangeEvent, MouseEvent } from 'react';
import { fade, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Paper from '@material-ui/core/Paper';
import ValueLabelComponent from './ValueLabelComponent';
import Disclaimer from './Disclaimer';
import getPopulation from '../data/population';

interface Data {
  country: string;
  confirmed: number;
  recovered: number;
  deaths: number;
  active: number;
  tests: number;
  mortalityRate: number;
  dailyIncrease: number;
  confirmedPerMillion: number;
  deathsPerMillion: number;
  activePerMillion: number;
  testsPerMillion: number;
}

function createData(
  country: string,
  confirmed: number,
  recovered: number,
  deaths: number,
  tests: number,
  dailyIncrease: number,
): Data {
  const population = getPopulation(country);

  return {
    country,
    confirmed,
    recovered,
    deaths,
    active: confirmed - recovered - deaths,
    tests,
    mortalityRate: confirmed > 0 ? deaths / confirmed : 0,
    dailyIncrease,
    confirmedPerMillion: +(10 ** 6 * confirmed / population).toFixed(0),
    deathsPerMillion: +(10 ** 6 * deaths / population).toFixed(0),
    activePerMillion: +(10 ** 6 * (confirmed - recovered - deaths) / population).toFixed(0),
    testsPerMillion: +(10 ** 6 * tests / population).toFixed(0),
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilisedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilisedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilisedThis.map(el => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'country', numeric: false, label: 'Country / Other' },
  { id: 'confirmed', numeric: true, label: 'Total Cases' },
  { id: 'recovered', numeric: true, label: 'Total Recovered' },
  { id: 'deaths', numeric: true, label: 'Total Deaths' },
  { id: 'active', numeric: true, label: 'Active Cases' },
  { id: 'tests', numeric: true, label: 'Total tests' },
  { id: 'mortalityRate', numeric: true, label: 'Case Fatality Ratio*' },
  { id: 'dailyIncrease', numeric: true, label: 'Daily % Increase' },
  { id: 'confirmedPerMillion', numeric: true, label: 'Total Cases / 1M'},
  { id: 'deathsPerMillion', numeric: true, label: 'Deaths / 1M'},
  { id: 'activePerMillion', numeric: true, label: 'Active Cases / 1M'},
  { id: 'testsPerMillion', numeric: true, label: 'Tests / 1M'},
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles<Theme, { openDrawer: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    container: {
      maxWidth: '100vw',
      maxHeight: '70vh',
      [theme.breakpoints.up('md')]: {
        maxWidth: props => props.openDrawer ? 'calc(100vw - 250px)' : 'calc(100vw - 50px)',
      },
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    slider: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      paddingRight: 40,
      paddingLeft: 20,
      width: '100%',
    },
  }),
);

interface Props {
  openDrawer: boolean;
  data: {
    country: string;
    latitude: number;
    longitude: number;
    data: {
      date: string;
      confirmed: number;
      deaths: number;
      recovered: number;
      tested?: number;
    }[];
  }[];
}
  
export default function EnhancedTable({ data, openDrawer }: Props) {
  const classes = useStyles({ openDrawer });
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('confirmed');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);

  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dateSet = new Set<string>();

  data.forEach(countryData => countryData.data.forEach(day => dateSet.add(day.date)));

  const dateList = Array.from(dateSet).sort();

  const [dateIndex, setDate] = useState(dateList.length - 1);
  const date = dateList[dateIndex];

  const rows = data.map(countryData => {
    const selectedDayIndex = countryData.data.findIndex(day => day.date === date);
    
    if (selectedDayIndex === -1) {
        return createData(countryData.country, 0, 0, 0, 0, 0);
    }

    const today = countryData.data[selectedDayIndex];
    const yesterday = selectedDayIndex > 0
      ? countryData.data[selectedDayIndex - 1]
      : { confirmed: 0, recovered: 0, deaths: 0 };
  
    return createData(
      countryData.country,
      today.confirmed,
      today.recovered,
      today.deaths,
      today.tested || 0,
      yesterday.confirmed > 0
        ? (today.confirmed - yesterday.confirmed) / yesterday.confirmed
        : 1,
    );
  });

  const handleChangeSlider = (_: unknown, value: number | number[]) => {
    setDate(value as number);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} >
          <Typography className={classes.title} variant="h4" id="tableTitle">
            Statistics by Country
          </Typography>
          <div className={classes.slider}>
            <Slider
              ValueLabelComponent={ValueLabelComponent}
              value={dateIndex}
              valueLabelFormat={(value: number) => dateList[value]}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 0, label: dateList[0] },
                { value: dateList.length - 1, label: dateList[dateList.length - 1] },
              ]}
              min={0}
              max={dateList.length - 1}
              onChange={handleChangeSlider}
            />
          </div>
        </Toolbar>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <caption><sup>*</sup>Case fatality ratio is defined as the number of total of deaths divided by the total confirmed cases.</caption>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.country}
                  >
                    <TableCell component="th" scope="row">{row.country}</TableCell>
                    <TableCell align="right">{row.confirmed.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.recovered.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.deaths.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.active.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.tests.toLocaleString()}</TableCell>
                    <TableCell align="right">{`${(100 * row.mortalityRate).toFixed(2)} %`}</TableCell>
                    <TableCell align="right">{`${(100 * row.dailyIncrease).toFixed(2)} %`}</TableCell>
                    <TableCell align="right">{row.confirmedPerMillion.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.deathsPerMillion.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.activePerMillion.toLocaleString()}</TableCell>
                    <TableCell align="right">{row.testsPerMillion.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Disclaimer />
      </Paper>
    </div>
  );
}