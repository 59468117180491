import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disclaimer: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export default function Disclaimer() {
  const classes = useStyles();

  return (
    <Toolbar className={classes.disclaimer}>
      <Typography>
        The data is updated continuously and the number of cases/deaths for the day is not final until the day is over.
      </Typography>
    </Toolbar>
  );
}
