import React, { useState } from 'react';
import { fade, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Chart,
  ValueAxis,
  ArgumentAxis,
  LineSeries,
  Legend,
  Title,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import ValueLabelComponent from './ValueLabelComponent';
import { Root, Item, Label, LineWithDotPoint } from './Chart';
import Disclaimer from './Disclaimer';

function ValueLabel(props: any) {
  const { text } = props;
  return (
    <ValueAxis.Label
      {...props}
      text={`${text}`}
    />
  );
}

const useStyles = makeStyles<Theme, { openDrawer: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100vw',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    slider: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      paddingRight: 40,
      paddingLeft: 20,
      width: '100%',
    },
    chart: {
      paddingRight: 20,
      paddingLeft: 20,
      maxWidth: '100vw',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: props => props.openDrawer ? 'calc(100vw - 250px)' : 'calc(100vw - 50px)',
        paddingRight: 0,
      },
    },
  }),
);

interface Props {
  openDrawer: boolean;
  data: {
    country: string;
    latitude: number;
    longitude: number;
    data: {
      date: string;
      confirmed: number;
      deaths: number;
      recovered: number;
    }[];
  }[];
}

export default function Country({ data, openDrawer }: Props) {
  const classes = useStyles({ openDrawer });

  const [country, setCountry] = useState('USA');

  const countryRawData = data
    .find(countryData => countryData.country === country)!;
  
  const countryData = {
    ...countryRawData,
    data: countryRawData.data.filter(day => day.confirmed > 0),
  };

  const dateList = countryData.data.map(day => day.date);
  
  const [dateRange, setDateRange] = useState([dateList.length - 1, 0]);
  const [movingAveragePeriod, setMovingAveragePeriod] = useState(7);
  
  const handleChangeSlider = (_: unknown, value: number | number[]) => {
    setDateRange([
      dateList.length - 1 - (value as number[])[0],
      dateList.length - 1 - (value as number[])[1],
    ]);
  };

  const handleMovingAveragePeriodChange = (_: unknown, value: number | number[]) => {
    setMovingAveragePeriod(value as number);
  };

  type DateMapValue = {
    date: number;
    confirmed: number;
    recovered: number;
    deaths: number;
    active: number;
    confirmedChange: number;
    recoveredChange: number;
    deathsChange: number;
    activeChange: number;
    dailyConfirmed: number;
    dailyRecovered: number;
    dailyDeaths: number;
    dailyConfirmedMovingAverage: number;
    dailyRecoveredMovingAverage: number;
    dailyDeathsMovingAverage: number;
  };

  const dateMap = new Map<string, DateMapValue>(
    dateList.map((date, index) => ([date, {
      date: index,
      confirmed: 0,
      recovered: 0,
      deaths: 0,
      active: 0,
      confirmedChange: 0,
      recoveredChange: 0,
      deathsChange: 0,
      activeChange: 0,
      dailyConfirmed: 0,
      dailyRecovered: 0,
      dailyDeaths: 0,
      dailyConfirmedMovingAverage: 0,
      dailyRecoveredMovingAverage: 0,
      dailyDeathsMovingAverage: 0,
    }])),
  );

  countryData.data.forEach((day) => {
    const dayData = dateMap.get(day.date)!;
    dayData.confirmed += day.confirmed;
    dayData.recovered += day.recovered;
    dayData.deaths += day.deaths;
    dayData.active += Math.max(0, day.confirmed - day.recovered - day.deaths);
  });

  const chartData = Array
    .from(dateMap.values())
    .map((day, index, array) => {
      if (index > 1) {
        const yesterday = array[index - 1];
  
        day.confirmedChange = yesterday.confirmed === 0
          ? (day.confirmed > 0 ? 100 : 0)
          : 100 * (day.confirmed / yesterday.confirmed - 1);
        day.confirmedChange = +day.confirmedChange.toFixed(2);
        day.recoveredChange = yesterday.recovered === 0
          ? (day.recovered > 0 ? 100 : 0)
          : 100 * (day.recovered / yesterday.recovered - 1);
        day.recoveredChange = +day.recoveredChange.toFixed(2);
        day.deathsChange = yesterday.deaths === 0
          ? (day.deaths > 0 ? 100 : 0)
          : 100 * (day.deaths / yesterday.deaths - 1);
        day.deathsChange = +day.deathsChange.toFixed(2);
        day.activeChange = yesterday.active === 0
          ? (day.active > 0 ? 100 : 0)
          : 100 * (day.active / yesterday.active - 1);
        day.activeChange = +day.activeChange.toFixed(2);
        
        day.dailyConfirmed = Math.max(0, day.confirmed - yesterday.confirmed);
        day.dailyRecovered = Math.max(0, day.recovered - yesterday.recovered);
        day.dailyDeaths = Math.max(0, day.deaths - yesterday.deaths);

        if (index >= movingAveragePeriod) {
          const earlier = array[index - movingAveragePeriod];
          day.dailyConfirmedMovingAverage = (yesterday.dailyConfirmedMovingAverage * movingAveragePeriod + day.dailyConfirmed - earlier.dailyConfirmed) / movingAveragePeriod;
          day.dailyRecoveredMovingAverage = (yesterday.dailyRecoveredMovingAverage * movingAveragePeriod + day.dailyRecovered - earlier.dailyRecovered) / movingAveragePeriod;
          day.dailyDeathsMovingAverage = (yesterday.dailyDeathsMovingAverage * movingAveragePeriod + day.dailyDeaths - earlier.dailyDeaths) / movingAveragePeriod;
        } else {
          day.dailyConfirmedMovingAverage = (yesterday.dailyConfirmedMovingAverage * index + day.dailyConfirmed) / (index + 1);
          day.dailyRecoveredMovingAverage = (yesterday.dailyRecoveredMovingAverage * index + day.dailyRecovered) / (index + 1);
          day.dailyDeathsMovingAverage = (yesterday.dailyDeathsMovingAverage * index + day.dailyDeaths) / (index + 1);
        }
      } else {
        day.dailyConfirmed = Math.max(0, day.confirmed);
        day.dailyRecovered = Math.max(0, day.recovered);
        day.dailyDeaths = Math.max(0, day.deaths);

        day.dailyConfirmedMovingAverage = day.dailyConfirmed;
        day.dailyRecoveredMovingAverage = day.dailyRecovered;
        day.dailyDeathsMovingAverage = day.dailyDeaths;
      }

      return day;
    })
    .map(day => ({
      ...day,
      dailyConfirmedMovingAverage: +day.dailyConfirmedMovingAverage.toFixed(2),
      dailyRecoveredMovingAverage: +day.dailyRecoveredMovingAverage.toFixed(2),
      dailyDeathsMovingAverage: +day.dailyDeathsMovingAverage.toFixed(2),
    }))
    .filter((_, index) => index >= dateList.length - 1 - dateRange[0] && index <= dateList.length - 1 - dateRange[1])
  
  const handleSelectCountry = (_: any, value: any) => {
    if (value) {
      setCountry(value.country as string);
    }
  };

  const format = () => (tick: string) => dateList[+tick];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant="h4" id="tableTitle">
            Timeline by Country
          </Typography>
          <div className={classes.slider}>
            <Slider
              ValueLabelComponent={ValueLabelComponent}
              value={[
                Math.max(0, dateList.length - 1 - dateRange[0]),
                Math.max(0, dateList.length - 1 - dateRange[1]),
              ]}
              valueLabelFormat={(value: number) => dateList[value]}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 0, label: dateList[0] },
                { value: dateList.length - 1, label: dateList[dateList.length - 1] },
              ]}
              min={0}
              max={dateList.length - 1}
              onChange={handleChangeSlider}
            />
          </div>
        </Toolbar>
        <Toolbar className={classes.root}>
          <Autocomplete
            options={data}
            getOptionLabel={(countryData: any) => countryData.country}
            style={{ width: '100%' }}
            value={{ country } as any}
            onChange={handleSelectCountry}
            renderInput={(params: any) => <TextField
              {...params}
              label="Country"
              variant="outlined"
              fullWidth
            />}
          />
        </Toolbar>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="confirmed"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Active Cases"
            valueField="active"
            argumentField="date"
            color="#aea96f"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="recovered"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="deaths"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text="Number of Cases" />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="dailyConfirmed"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="dailyRecovered"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="dailyDeaths"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text="Daily Cases" />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="confirmedChange"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="recoveredChange"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="deathsChange"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Active Cases"
            valueField="activeChange"
            argumentField="date"
            color="#aea96f"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text="Daily % Change" />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant="h4" id="tableTitle">
            Average over a period
          </Typography>
          <div className={classes.slider}>
            <Slider
              ValueLabelComponent={ValueLabelComponent}
              value={movingAveragePeriod}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              marks={[
                { value: 1, label: '1' },
                { value: 7, label: '7' },
                { value: 14, label: '14' },
                { value: 28, label: '28' },
              ]}
              step={1}
              min={1}
              max={28}
              onChange={handleMovingAveragePeriodChange}
            />
          </div>
        </Toolbar>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="dailyConfirmedMovingAverage"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="dailyRecoveredMovingAverage"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="dailyDeathsMovingAverage"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text={`${movingAveragePeriod}-day Moving Averaged Daily Cases`} />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Disclaimer />
      </Paper>
    </div>
  );
}
