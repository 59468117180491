import React, { useState } from 'react';
import { fade, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Legend,
  Title,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import ValueLabelComponent from './ValueLabelComponent';
import { Root, Item, Label, LineWithDotPoint } from './Chart';
import Disclaimer from './Disclaimer';

function ValueLabel(props: any) {
  const { text } = props;
  return (
    <ValueAxis.Label
      {...props}
      text={`${text}`}
    />
  );
}

const useStyles = makeStyles<Theme, { openDrawer: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100vw',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    slider: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      paddingRight: 40,
      paddingLeft: 20,
      width: '100%',
    },
    chart: {
      paddingRight: 20,
      paddingLeft: 20,
      maxWidth: '100vw',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: props => props.openDrawer ? 'calc(100vw - 250px)' : 'calc(100vw - 50px)',
        paddingRight: 0,
      },
    },
  }),
);

interface Props {
  openDrawer: boolean;
  data: {
    country: string;
    latitude: number;
    longitude: number;
    data: {
      date: string;
      confirmed: number;
      deaths: number;
      recovered: number;
    }[];
  }[];
}

export default function Timeline({ data, openDrawer }: Props) {
  const classes = useStyles({ openDrawer });
  
  const dateSet = new Set<string>();
  
  data.forEach(countryData => countryData.data.forEach(day => dateSet.add(day.date)));
  
  const dateList = Array.from(dateSet).sort();

  const [dateRange, setDateRange] = useState([0, dateList.length - 1]);
  
  const handleChangeSlider = (_: unknown, value: number | number[]) => {
    setDateRange(value as number[]);
  };

  type DateMapValue = {
    date: number;
    confirmed: number;
    recovered: number;
    deaths: number;
    active: number;
    confirmedChange: number;
    recoveredChange: number;
    deathsChange: number;
    activeChange: number;
    dailyConfirmed: number;
    dailyRecovered: number;
    dailyDeaths: number;
  };

  const dateMap = new Map<string, DateMapValue>(
    dateList.map((date, index) => ([date, {
      date: index,
      confirmed: 0,
      recovered: 0,
      deaths: 0,
      active: 0,
      confirmedChange: 0,
      recoveredChange: 0,
      deathsChange: 0,
      activeChange: 0,
      dailyConfirmed: 0,
      dailyRecovered: 0,
      dailyDeaths: 0,
    }])),
  );

  data.forEach(countryData => {
    countryData.data.forEach(day => {
      const dayData = dateMap.get(day.date)!;
      dayData.confirmed += day.confirmed;
      dayData.recovered += day.recovered;
      dayData.deaths += day.deaths;
      dayData.active += Math.max(0, day.confirmed - day.recovered - day.deaths);
    });
  });

  const chartData = Array
    .from(dateMap.values())
    .map((day, index, array) => {
        if (index > 1) {
          const yesterday = array[index - 1];
    
          day.confirmedChange = yesterday.confirmed === 0
            ? (day.confirmed > 0 ? 100 : 0)
            : 100 * (day.confirmed / yesterday.confirmed - 1);
          day.recoveredChange = yesterday.recovered === 0
            ? (day.recovered > 0 ? 100 : 0)
            : 100 * (day.recovered / yesterday.recovered - 1);
          day.deathsChange = yesterday.deaths === 0
            ? (day.deaths > 0 ? 100 : 0)
            : 100 * (day.deaths / yesterday.deaths - 1);
          day.activeChange = yesterday.active === 0
            ? (day.active > 0 ? 100 : 0)
            : 100 * (day.active / yesterday.active - 1);

          day.dailyConfirmed = Math.max(0, day.confirmed - yesterday.confirmed);
          day.dailyRecovered = Math.max(0, day.recovered - yesterday.recovered);
          day.dailyDeaths = Math.max(0, day.deaths - yesterday.deaths);
        } else {
          day.dailyConfirmed = Math.max(0, day.confirmed);
          day.dailyRecovered = Math.max(0, day.recovered);
          day.dailyDeaths = Math.max(0, day.deaths);
        }
  
        return day;
      })  
      .filter((_, index) => index >= dateRange[0] && index <= dateRange[1])

  const format = () => (tick: string) => dateList[+tick];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} >
          <Typography className={classes.title} variant="h4" id="tableTitle">
            World Timeline
          </Typography>
          <div className={classes.slider}>
            <Slider
              ValueLabelComponent={ValueLabelComponent}
              value={dateRange}
              valueLabelFormat={(value: number) => dateList[value]}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 0, label: dateList[0] },
                { value: dateList.length - 1, label: dateList[dateList.length - 1] },
              ]}
              min={0}
              max={dateList.length - 1}
              onChange={handleChangeSlider}
            />
          </div>
        </Toolbar>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="confirmed"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Active Cases"
            valueField="active"
            argumentField="date"
            color="#aea96f"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="recovered"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="deaths"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text="Number of Cases" />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="dailyConfirmed"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="dailyRecovered"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="dailyDeaths"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text="Daily Cases" />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Chart data={chartData}>
          <ArgumentAxis showGrid={true} tickFormat={format} />
          <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
          <LineSeries
            name="Confirmed Cases"
            valueField="confirmedChange"
            argumentField="date"
            color="#003b74"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Recovered Cases"
            valueField="recoveredChange"
            argumentField="date"
            color="#6e7b75"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Deaths"
            valueField="deathsChange"
            argumentField="date"
            color="#c3113c"
            seriesComponent={LineWithDotPoint}
          />
          <LineSeries
            name="Active Cases"
            valueField="activeChange"
            argumentField="date"
            color="#aea96f"
            seriesComponent={LineWithDotPoint}
          />
          <Legend
            position="bottom"
            rootComponent={Root as any}
            itemComponent={Item as any}
            labelComponent={Label as any}
          />
          <Title text="Daily % Change" />
          <EventTracker />
          <Tooltip />
        </Chart>
        <Disclaimer />
      </Paper>
    </div>
  );
}
