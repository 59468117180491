import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Path from './path';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    box: {
      padding: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default function Methodology() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <Typography className={classes.title} variant="h4">
            Methodology
          </Typography>
          <Typography paragraph align="justify">
            Current unknowns of COVID-19 include the prevalence of asymptomatic cases and the death rate. We consequently make a number of assumptions when modeling the outbreak. Factors such as population density, age distribution and human behavior, are always difficult to incorporate into a model and it will never be possible to replicate all the complexities of the pandemic.
          </Typography>
          <Typography paragraph align="justify">
            As Presidents Eisenhower and Nixon said: ‘I have always found that plans are useless, but planning is indispensable.’ Mathematical models provide essential qualitative information to assist decision makers who seek constrained optimizations, yet reality will always be at least a little different.
          </Typography>
          <Typography paragraph align="justify">
            We have developed a compartmental epidemic model with implementation in the <Link component={RouterLink} to={`/${Path.Calculator}`} color="inherit">Policy Simulator</Link>. The toolkit allows you to specify initial disease parameters, whilst selecting and varying policies over time.
          </Typography>
          <Typography paragraph align="justify">
            The <Link component={RouterLink} to={`/${Path.Calculator}`}>Policy Simulator</Link> has both basic and advanced interfaces to cater to multiple skill sets. Initial settings, contact rate, duration per compartment, probability of recovery per compartment and policy impact on contact rates, have been selected from a number of research papers.
          </Typography>
          <Typography paragraph align="justify">
            Determining an appropriate set of timed policies in this intensifying crisis is a mighty burden for any policy maker. We hope this tool will assist you to forge the path ahead - to go forward at all is to go forward together.
          </Typography>
          <Typography paragraph align="justify">
            God bless our fellow Americans and fellow citizens of the world community.
          </Typography>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.title} variant="h4">
            Assumptions
          </Typography>
          <ul>
            <li><Typography paragraph>Infectees are symptomatic;</Typography></li>
            <li><Typography paragraph>COVID-19 seasonality toggle in advanced interface;</Typography></li>
            <li><Typography paragraph>Infectees do not self-isolate upon experiencing symptoms;</Typography></li>
            <li><Typography paragraph>During incubation, a person is half as infectious as when showing symptoms;</Typography></li>
            <li><Typography paragraph>Policy measures have immediate effect when implemented and are constant in time;</Typography></li>
            <li><Typography paragraph>Incubation delays full effects of lockdown by 11-14 days;</Typography></li>
            <li><Typography paragraph>Contracting COVID-19 confers long-term immunity;</Typography></li>
          </ul>
          <Typography paragraph>Policy effectiveness depends on implementation and population behavior.</Typography>
        </Box>
      </Paper>
    </div>
  );
}
