import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Map from './components/Map';
import Table from './components/Table';
import Timeline from './components/Timeline';
import Country from './components/Country';
import StateMap from './components/StateMap';
import StateTable from './components/StateTable';
import State from './components/State';
import UkMap from './components/UkMap';
import UkTable from './components/UkTable';
import UkTimeline from './components/UkTimeline';
import Calculator from './components/Calculator';
import Methodology from './components/Methodology';
import About from './components/About';
import Request from './components/Request';
import Path from './components/path';
import { Policy } from './model/policies';
import logo from './RNF_logo_hor_globe.svg';
import getPopulation from './data/population';
import getUkPopulation from './data/uk-population';
import getStatePopulation from './data/state-population';

type Example = {
  [name: string]: {
    initialPopulation: number;
    initialExposed: number;
    initialInfected: number;
    reproductionNumberEstimate: number;
    recoveryProbabilityFromInfection: number;
    temporaryImmunityFromHospitalisationProbability: number;
    temporaryImmunityFromIcuProbability: number;
    startDate: string;
    hospitalCapacity: number;
    icuCapacity: number;
    policies: {
      begin: number;
      end: number;
      policies: Policy[];
    }[];
  };
};

type Data = {
  country: string;
  latitude: number;
  longitude: number;
  data: {
    date: string;
    confirmed: number;
    deaths: number;
    recovered: number;
    tested?: number;
  }[];
}[];

type DataUsa = {
  state: string;
  latitude: number;
  longitude: number;
  data: {
    date: string;
    confirmed: number;
    deaths: number;
    recovered: number;
    tested?: number;
  }[];
}[];

type DataUK = {
  nationRegion: string;
  latitude: number;
  longitude: number;
  data: {
    date: string;
    confirmed: number;
    deaths: number;
    recovered: number;
    tested?: number;
  }[];
}[];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#002046',
    },
    secondary: {
      main: '#c3113c',
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: 'librefranklin',
    h1: {
      textTransform: 'capitalize',
    },
    h6: {
      fontSize: 14,
      fontFamily: 'RipeApricotBold',
    },
    h5: {
      fontSize: 16,
    },
    h4: {
      color: '#c3113c',
      fontSize: 18,
    },
  },
});

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      minHeight: 90,
    },
    title: {
      flexGrow: 1,
      textAlign: 'right',
      display: 'block',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        display: 'block',
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      background: 'rgb(143, 130, 121);',
      color: 'white',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      height: 90,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#f4f4f4',
      [theme.breakpoints.up('md')]: {
        marginLeft: -drawerWidth,
        padding: theme.spacing(3),
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    centre: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function ListItemLink({ primary, to }: {
  primary: string;
  to: string;
}) {

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps: any, ref: any) => (
        <Link ref={ref as any} to={to} {...linkProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={CustomLink as any}>
        <ListItemText
          style={{ textTransform: 'uppercase', textAlign: 'center' }}
          primary={primary}
        />
      </ListItem>
    </li>
  );
}

export default function App() {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(matches);
  const [initialised, setInitialised] = useState(false);
  const [data, setData] = useState<Data | null>(null);
  const [dataUsa, setDataUsa] = useState<DataUsa | null>(null);
  const [dataUK, setDataUK] = useState<DataUK | null>(null);

  useEffect(() => {
    const loadApiData = () => {
      axios
        .get('/aggregate/daily/latest.json')
        .then(response => {
          setData(response.data)
        });
      axios
        .get('/aggregate/daily/usa/latest.json')
        .then(response => {
          setDataUsa(response.data)
        });
      axios
        .get('/aggregate/daily/uk/latest.json')
        .then(response => {
          setDataUK(response.data)
        });
    }
    const interval = setInterval(loadApiData, 30 * 60 * 1000);

    loadApiData();

    return () => clearInterval(interval);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (matches && !initialised) {
    setInitialised(true);
    setOpen(true);
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                onClick={handleDrawerOpen}
                aria-label="open drawer"
              >
                <img src={logo} alt="Richard Nixon Foundation" />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                COVID-19 POLICY
              </Typography>
              {
                !matches && (
                  <IconButton style={{ color: 'white' }} onClick={handleDrawerOpen}>
                    <MenuIcon />
                  </IconButton>
                )
              }
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant={matches ? 'persistent' : 'temporary'}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton style={{ color: 'white' }} onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItemLink to={`/${Path.State}`} primary={'US State Timeline'} />
              <ListItemLink to={`/${Path.TableUsa}`} primary={'US State Table'} />
              <ListItemLink to={`/${Path.MapUsa}`} primary={'US Map'} />
              <ListItemLink to={`/${Path.CalculatorUsa}`} primary={'US Policy Simulator'} />
            </List>
            <Divider />
            <List>
              <ListItemLink to={`/${Path.TimelineUk}`} primary={'UK Timeline'} />
              <ListItemLink to={`/${Path.TableUk}`} primary={'UK Table'} />
              <ListItemLink to={`/${Path.MapUk}`} primary={'UK Map'} />
              <ListItemLink to={`/${Path.CalculatorUk}`} primary={'UK Policy Simulator'} />
            </List>
            <Divider />
            <List>
              <ListItemLink to={`/${Path.Timeline}`} primary={'World Timeline'} />
              <ListItemLink to={`/${Path.Map}`} primary={'World Map'} />
            </List>
            <Divider />
            <List>
              <ListItemLink to={`/${Path.Country}`} primary={'Country Timeline'} />
              <ListItemLink to={`/${Path.Table}`} primary={'Country Table'} />
              <ListItemLink to={`/${Path.Calculator}`} primary={'International Policy Simulator'} />
            </List>
            <Divider />
            <List>
              <ListItemLink to={`/${Path.Methodology}`} primary={'Methodology'} />
              <ListItemLink to={`/${Path.Request}`} primary={'Request'} />
              <ListItemLink to={`/${Path.About}`} primary={'About'} />
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Switch>
              <Route path={`/${Path.Map}`}>
                {
                  data ? (
                    <Map data={data} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.Table}`}>
                {
                  data ? (
                    <Table data={data} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.Timeline}`}>
                {
                  data ? (
                    <Timeline data={data} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.Country}`}>
                {
                  data ? (
                    <Country data={data} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.MapUsa}`}>
                {
                  dataUsa ? (
                    <StateMap data={dataUsa} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.TableUsa}`}>
                {
                  dataUsa ? (
                    <StateTable data={dataUsa} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.State}`}>
                {
                  dataUsa ? (
                    <State data={dataUsa} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.MapUk}`}>
                {
                  dataUK ? (
                    <UkMap data={dataUK} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.TableUk}`}>
                {
                  dataUK ? (
                    <UkTable data={dataUK} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.TimelineUk}`}>
                {
                  dataUK ? (
                    <UkTimeline data={dataUK} openDrawer={open} />
                  ) : (
                    <div className={classes.centre}><CircularProgress /></div>
                  )
                }
              </Route>
              <Route path={`/${Path.About}`}>
                <About />
              </Route>
              <Route path={`/${Path.Request}`}>
                <Request />
              </Route>
              <Route path={`/${Path.Methodology}`}>
                <Methodology />
              </Route>
              <Route path={`/${Path.Calculator}`}>
                <Calculator
                  key={Path.Calculator}
                  label="Countries"
                  keyName="country"
                  getPopulation={getPopulation}
                  data={data || []}
                  openDrawer={open}
                />
              </Route>
              <Route path={`/${Path.CalculatorUk}`}>
                <Calculator
                  key={Path.CalculatorUk}
                  label="Nation/Region"
                  keyName="nationRegion"
                  getPopulation={getUkPopulation}
                  data={dataUK || []}
                  openDrawer={open}
                />
              </Route>
              <Route path={`/${Path.CalculatorUsa}`}>
                <Calculator
                  key={Path.CalculatorUsa}
                  label="States"
                  keyName="state"
                  getPopulation={getStatePopulation}
                  data={dataUsa || []}
                  openDrawer={open}
                />
              </Route>
              <Route path='/'>
                <Calculator
                  key={Path.CalculatorUsa}
                  label="States"
                  keyName="state"
                  getPopulation={getStatePopulation}
                  data={dataUsa || []}
                  openDrawer={open}
                />
              </Route>
            </Switch>
          </main>
        </div>
      </ThemeProvider>
    </Router>
  );
}
