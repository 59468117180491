import React, { useMemo, useState, useCallback } from 'react';
import { fade, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Chart,
  ValueAxis,
  ArgumentAxis,
  LineSeries,
  Legend,
  Title,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import { Root, Item, Label, LineWithDotPoint } from './Chart';
import Model from '../model';
import { Policy, policyMap } from '../model/policies';

function normalisePulsePolicy(pulsePolicy: {
  policies: Policy[];
  begin: number;
  onPeriod: number;
  offPeriod: number;
}, simulationDuration: number) {
  const { policies, begin, onPeriod, offPeriod } = pulsePolicy;

  const policiesList = [];

  for (let start = begin; start <= simulationDuration; start += onPeriod + offPeriod) {
    policiesList.push({
      policies,
      begin: start,
      end: start + onPeriod,
    });
  }

  return policiesList;
}

function combinePolicy(
  policies: {
    policies: Policy[];
    begin: number;
    end: number;
  }[],
  newPolicy: {
    policies: Policy[];
    begin: number;
    end: number;
  },
) {
  return policies.reduce((policies, policy) => {
    if (policies.length === 0) {
      return [policy];
    }

    const lastPolicy = policies[policies.length - 1];
    if (policy.end < lastPolicy.begin) {
      return policies.slice(0, -1).concat([policy, lastPolicy]);
    }

    if (policy.begin > lastPolicy.end) {
      return policies.concat(policy);
    }

    policies = policies.slice(0, -1);

    if (policy.begin !== lastPolicy.begin) {
      policies = policies.concat({
        policies: policy.begin < lastPolicy.begin ? policy.policies : newPolicy.policies,
        begin: Math.min(policy.begin, lastPolicy.begin),
        end: Math.max(policy.begin, lastPolicy.begin) - 1,
      });
    }

    policies = policies.concat({
      policies: Array.from(new Set(policy.policies.concat(newPolicy.policies))),
      begin: Math.max(policy.begin, lastPolicy.begin),
      end: Math.min(policy.end, lastPolicy.end),
    });

    if (policy.end !== lastPolicy.end) {
      policies = policies.concat({
        policies: policy.end < lastPolicy.end ? newPolicy.policies : policy.policies,
        begin: Math.min(policy.end, lastPolicy.end) + 1,
        end: Math.max(policy.end, lastPolicy.end),
      });
    }

    return policies;
  }, [newPolicy]);
}

function combinePolicies(
  policies: {
    policies: Policy[];
    begin: number;
    end: number;
  }[],
  pulsePolicies: {
    policies: Policy[];
    begin: number;
    onPeriod: number;
    offPeriod: number;
  }[],
  simulationDuration: number
) {
  const pulsePoliciesNormalised = pulsePolicies.map(pulsePolicy => normalisePulsePolicy(pulsePolicy, simulationDuration)).flat();

  return pulsePoliciesNormalised.reduce((policies, policy) => combinePolicy(policies, policy), policies);
}

function ValueLabel(props: any) {
  const { text } = props;
  return (
    <ValueAxis.Label
      {...props}
      text={`${text}`}
    />
  );
}

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const useStyles = makeStyles<Theme, { openDrawer: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100vw',
    },
    policyTitle: {
      width: '100%',
    },
    disclaimer: {
      marginBottom: theme.spacing(2),
    },
    autocomplete: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    tableContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      maxWidth: '100vw',
      [theme.breakpoints.up('md')]: {
        maxWidth: props => props.openDrawer ? 'calc(100vw - 250px)' : 'calc(100vw - 50px)',
      },
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    slider: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
    },
    sliderPolicy: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      minWidth: '30vw',
      maxWidth: '30vw',
    },
    chart: {
      paddingRight: 20,
      paddingLeft: 20,
      maxWidth: '100vw',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: props => props.openDrawer ? 'calc(100vw - 250px)' : 'calc(100vw - 50px)',
        paddingRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '30vw',
      maxWidth: '30vw',
    },
  }),
);

interface Props {
  openDrawer: boolean;
  label: string;
  keyName: 'country' | 'nationRegion' | 'state';
  getPopulation: (name: string) => number;
  data: ({
    latitude: number;
    longitude: number;
    data: {
      date: string;
      confirmed: number;
      deaths: number;
      recovered: number;
    }[];
  } & ({
    country: string;
  } | {
    nationRegion: string;
  } | {
    state: string;
  }))[];
}

export default function Calculator({
  label,
  keyName,
  getPopulation,
  data,
  openDrawer,
}: Props) {
  const classes = useStyles({ openDrawer });

  const [advanced, setAdvanced] = useState(false);
  const [example, setExample] = useState<string | null>(null);
  const [dataSource, setDataSource] = useState<({
    date: string;
    confirmed: number;
    deaths: number;
    recovered: number;
  }[] | null)>(null);

  // Country parameters
  const [initialPopulation, setInitialPopulation] = useState(62);
  const [initialExposed, setInitialExposed] = useState(10);
  const [initialInfected, setInitialInfected] = useState(10);
  const [icuCapacity, setIcuCapacity] = useState(20);
  const [reproductionNumberEstimate, setReproductionNumberEstimate] = useState(2.4);
  const [simulationDuration, setSimulationDuration] = useState(365);
  const [youngPercentage, setYoungPercentage] = useState(0.3);
  const [adultPercentage, setAdultPercentage] = useState(0.52);

  // Disease parameters
  // Probabilities
  const [temporaryImmunityFromVaccineProbability, setTemporaryImmunityFromVaccineProbability] = useState(0.6);
  const [asyntomaticProbability, setAsyntomaticProbability] = useState(0.1);
  const [hospitalisationProbability, setHospitalisationProbability] = useState(0.06);
  const [directDeathProbability, setDirectDeathProbability] = useState(0.002);
  const [temporaryImmunityFromHospitalisationProbability, setTemporaryImmunityFromHospitalisationProbability] = useState(0.5);
  const [temporaryImmunityFromIcuProbability, setTemporaryImmunityFromIcuProbability] = useState(0.5);
  const [immunityProbability, setImmunityProbability] = useState(1);
  const [seasonalityFactor, setSeasonaliyFactor] = useState(1);
  
  // 1.2.2 Durations
  const [observeEffectOfVaccineDuration, setObserveEffectOfVaccineDuration] = useState(10);
  const [incubationDuration, setIncubationDuration] = useState(5);
  const [mildDuration, setMildDuration] = useState(10);
  const [hospitalisationDuration, setHospitalisationDuration] = useState(11);
  const [icuDuration, setIcuDuration] = useState(14);
  const [recoveryToImmunityDuration, setRecoveryToImmunityDuration] = useState(5000);

  const [policies, setPolicies] = useState<{
    policies: Policy[];
    begin: number;
    end: number;
  }[]>([
    {
      policies: [Policy.LargeEventsBanned, Policy.SchoolsUniversitiesClosed, Policy.ContactTracing],
      begin: 12,
      end: 16,
    },
    {
      policies: [Policy.Lockdown, Policy.ContactTracing, Policy.PhysicalDistancing, Policy.LargeEventsBanned],
      begin: 40,
      end: 50,
    },
  ]);

  // Policies
  const [temporaryPolicies, setTemporaryPolicies] = React.useState<Policy[]>([]);
  const [temporaryPoliciesDayRange, setTemporaryPoliciesDayRange] = React.useState<number[]>([0, simulationDuration]);

  const handleTemporaryPolicyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTemporaryPolicies(event.target.value as Policy[]);
  };

  const handleAddPolicy = useCallback(() => {
    setPolicies(policies.concat({
      policies: temporaryPolicies,
      begin: temporaryPoliciesDayRange[0],
      end: temporaryPoliciesDayRange[1],
    }));
    setTemporaryPolicies([]);
    setTemporaryPoliciesDayRange([temporaryPoliciesDayRange[1] + 1, simulationDuration]);
  }, [policies, temporaryPolicies, temporaryPoliciesDayRange, simulationDuration]);

  const [pulsePolicies, setPulsePolicies] = useState<{
    policies: Policy[];
    begin: number;
    onPeriod: number;
    offPeriod: number;
  }[]>([
    {
      policies: [Policy.PhysicalDistancing],
      begin: 60,
      onPeriod: 14,
      offPeriod: 14,
    },
  ]);

  // Policy Pulse
  const [temporaryPulsePolicies, setTemporaryPulsePolicies] = React.useState<Policy[]>([]);
  const [temporaryPulsePoliciesBegin, setTemporaryPulsePoliciesBegin] = React.useState<number>(0);
  const [temporaryPulsePoliciesOnPeriod, setTemporaryPulsePoliciesOnPeriod] = React.useState<number>(14);
  const [temporaryPulsePoliciesOffPeriod, setTemporaryPulsePoliciesOffPeriod] = React.useState<number>(14);

  const handleTemporaryPulsePolicyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTemporaryPulsePolicies(event.target.value as Policy[]);
  };

  const handleAddPulsePolicy = useCallback(() => {
    setPulsePolicies(pulsePolicies.concat({
      policies: temporaryPulsePolicies,
      begin: temporaryPulsePoliciesBegin,
      onPeriod: temporaryPulsePoliciesOnPeriod,
      offPeriod: temporaryPulsePoliciesOffPeriod,
    }));
    setTemporaryPulsePolicies([]);
    setTemporaryPulsePoliciesBegin(0);
    setTemporaryPulsePoliciesOnPeriod(14);
    setTemporaryPulsePoliciesOffPeriod(14);
  }, [
    pulsePolicies,
    temporaryPulsePolicies,
    temporaryPulsePoliciesBegin,
    temporaryPulsePoliciesOnPeriod,
    temporaryPulsePoliciesOffPeriod,
  ]);

  const handleAdvancedChange = useCallback(() => {
    setAdvanced(!advanced);
  }, [advanced]);

  const options = useMemo(() => {
    return data
      ? data.map(data => (data as any)[keyName])
      : [];
  }, [data]);

  const chartData = useMemo(
    () => {
      const modelPolicies = combinePolicies(
        policies.reduce((policies, policy) => combinePolicy(policies, policy), [] as {
          policies: Policy[];
          begin: number;
          end: number;
        }[]),
        pulsePolicies,
        simulationDuration,
      );

      const data = Model({
        initialPopulation: initialPopulation * 10 ** 6,
        initialExposed,
        initialInfected,
        icuCapacity: icuCapacity * 10 ** 3,
        reproductionNumberEstimate,
        youngPercentage,
        adultPercentage,
        temporaryImmunityFromVaccineProbability,
        asyntomaticProbability,
        hospitalisationProbability,
        directDeathProbability,
        temporaryImmunityFromHospitalisationProbability,
        temporaryImmunityFromIcuProbability,
        immunityProbability,
        seasonalityFactor,
        observeEffectOfVaccineDuration,
        incubationDuration,
        mildDuration,
        hospitalisationDuration,
        icuDuration,
        recoveryToImmunityDuration,
        policies: modelPolicies.map(policy => policy.policies),
        policyTimes: modelPolicies,
        duration: simulationDuration,
      }); 

      if (dataSource) {
        data.forEach((value: any, index: number) => {
          if (dataSource[index]) {
            value.realConfirmed = dataSource[index].confirmed;
            value.realDeaths = dataSource[index].deaths;
          }
        });
      }

      return data;
    },
    [
      initialPopulation,
      initialExposed,
      initialInfected,
      icuCapacity,
      reproductionNumberEstimate,
      youngPercentage,
      adultPercentage,
      temporaryImmunityFromVaccineProbability,
      asyntomaticProbability,
      hospitalisationProbability,
      directDeathProbability,
      temporaryImmunityFromHospitalisationProbability,
      temporaryImmunityFromIcuProbability,
      immunityProbability,
      seasonalityFactor,
      observeEffectOfVaccineDuration,
      incubationDuration,
      mildDuration,
      hospitalisationDuration,
      icuDuration,
      recoveryToImmunityDuration,
      policies,
      pulsePolicies,
      simulationDuration,
      dataSource,
    ],
  );

  const format = () => (tick: string) => tick;

  const handleSelectExample = (_: any, value: any) => {
    if (value) {
      setExample(value as string);
      
      const selectedData = data.find(data => keyName in data && (data as any)[keyName] === value);

      if (!selectedData) {
        return;
      }

      const population = getPopulation((selectedData as any)[keyName]!);

      setInitialPopulation((population - selectedData.data[selectedData.data.length - 1].deaths) / (10 ** 6));
      setInitialExposed(selectedData.data[selectedData.data.length - 1].confirmed);
      setInitialInfected(selectedData.data[selectedData.data.length - 1].confirmed);

      setDataSource(null);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant="h4" id="tableTitle">
            Policy Simulator
          </Typography>
          <FormControlLabel
            control={<Switch checked={advanced} onChange={handleAdvancedChange} color="primary" />}
            label="Advanced"
          />
        </Toolbar>
        <Toolbar className={classes.disclaimer}>
          <Typography>
            The Policy Simulator is intended for government use only. It is provided solely for illustrative purposes and neither the data within the model nor the model outputs can be relied upon. Any decision, sovereign or otherwise, arising from or connected with the use of this tool is entirely at the risk of the user.
          </Typography>
        </Toolbar>
        <Toolbar className={classes.autocomplete}>
          <Autocomplete
            options={options}
            style={{ width: '100%' }}
            value={example}
            onChange={handleSelectExample}
            renderInput={(params: any) => <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
            />}
          />
        </Toolbar>
        <Toolbar>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Typography>
                Simulation Duration<span className="policy-number">: {+simulationDuration.toFixed(1)}</span>
              </Typography>
              <div className={`${classes.slider} policy-slider`}>
                <Slider
                  track={false}
                  value={simulationDuration}
                  onChange={(_: any, value: any) => setSimulationDuration(value)}
                  step={1}
                  marks={[
                    { value: 30, label: '30' },
                    { value: 500, label: '500' },
                  ]}
                  min={30}
                  max={500}
                  valueLabelDisplay="auto"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
                Population (in millions)<span className="policy-number">: {+initialPopulation.toFixed(3)}</span>
              </Typography>
              <div className={`${classes.slider} policy-slider`}>
                <Slider
                  track={false}
                  value={+initialPopulation.toFixed(2)}
                  onChange={(_: any, value: any) => setInitialPopulation(value)}
                  step={1}
                  marks={[
                    { value: 1, label: '1' },
                    { value: 500, label: '500' },
                  ]}
                  min={1}
                  max={500}
                  valueLabelDisplay="auto"
                />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        {
          advanced && (
            <>
              <Toolbar>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Initial conditions
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      ICU bed capacity (in thousands)<span className="policy-number">: {+icuCapacity.toFixed(3)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+icuCapacity.toFixed(1)}
                        onChange={(_: any, value: any) => setIcuCapacity(value)}
                        step={0.1}
                        marks={[
                          { value: 0.1, label: '0.1' },
                          { value: 500, label: '500' },
                        ]}
                        min={0.1}
                        max={500}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Initial reproduction number<span className="policy-number">: {+reproductionNumberEstimate.toFixed(1)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+reproductionNumberEstimate.toFixed(1)}
                        onChange={(_: any, value: any) => setReproductionNumberEstimate(value)}
                        step={0.1}
                        marks={[
                          { value: 0.1, label: '0.1' },
                          { value: 5, label: '5' },
                        ]}
                        min={0.1}
                        max={5}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Initial incubation cases<span className="policy-number">: {+initialExposed.toFixed(1)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+initialExposed.toFixed(2)}
                        onChange={(_: any, value: any) => setInitialExposed(value)}
                        step={1}
                        marks={[
                          { value: 10, label: '10' },
                          { value: 10000, label: '10000' },
                        ]}
                        min={10}
                        max={10000}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Initial infected cases<span className="policy-number">: {+initialInfected.toFixed(1)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+initialInfected.toFixed(2)}
                        onChange={(_: any, value: any) => setInitialInfected(value)}
                        step={1}
                        marks={[
                          { value: 10, label: '10' },
                          { value: 10000, label: '10000' },
                        ]}
                        min={10}
                        max={10000}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Percentage of young population<span className="policy-number">: {+youngPercentage.toFixed(1)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+youngPercentage.toFixed(2)}
                        onChange={(_: any, value: any) => setYoungPercentage(value)}
                        step={0.1}
                        marks={[
                          { value: 10, label: '10' },
                          { value: 50, label: '50' },
                        ]}
                        min={10}
                        max={50}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Percentage of adult population<span className="policy-number">: {+adultPercentage.toFixed(1)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+adultPercentage.toFixed(2)}
                        onChange={(_: any, value: any) => setAdultPercentage(value)}
                        step={0.1}
                        marks={[
                          { value: 10, label: '10' },
                          { value: 50, label: '50' },
                        ]}
                        min={10}
                        max={50}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
              <Toolbar>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Probabilities
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of getting temporary immunity from vaccination<span className="policy-number">: {+temporaryImmunityFromVaccineProbability.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+temporaryImmunityFromVaccineProbability.toFixed(2)}
                        onChange={(_: any, value: any) => setTemporaryImmunityFromVaccineProbability(value)}
                        step={0.01}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of asymptomatic infection<span className="policy-number">: {+asyntomaticProbability.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+asyntomaticProbability.toFixed(2)}
                        onChange={(_: any, value: any) => setAsyntomaticProbability(value)}
                        step={0.01}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of being hospitalised after being infected<span className="policy-number">: {+hospitalisationProbability.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+hospitalisationProbability.toFixed(2)}
                        onChange={(_: any, value: any) => setHospitalisationProbability(value)}
                        step={0.01}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of dying straight out of being infected<span className="policy-number">: {+directDeathProbability.toFixed(3)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+directDeathProbability.toFixed(3)}
                        onChange={(_: any, value: any) => setDirectDeathProbability(value)}
                        step={0.001}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of getting temporary immunity from being infected<span className="policy-number">: {+temporaryImmunityFromHospitalisationProbability.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+temporaryImmunityFromHospitalisationProbability.toFixed(2)}
                        onChange={(_: any, value: any) => setTemporaryImmunityFromHospitalisationProbability(value)}
                        step={0.01}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of getting temporary immunity from being hospitalised<span className="policy-number">: {+temporaryImmunityFromIcuProbability.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+temporaryImmunityFromIcuProbability.toFixed(2)}
                        onChange={(_: any, value: any) => setTemporaryImmunityFromIcuProbability(value)}
                        step={0.01}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Probability of getting permanent immunity<span className="policy-number">: {+immunityProbability.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+immunityProbability.toFixed(2)}
                        onChange={(_: any, value: any) => setImmunityProbability(value)}
                        step={0.01}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Seasonality Factor<span className="policy-number">: {+seasonalityFactor.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+seasonalityFactor.toFixed(2)}
                        onChange={(_: any, value: any) => setSeasonaliyFactor(value)}
                        step={1}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 1, label: '1' },
                        ]}      
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>                  
                </Grid>
              </Toolbar>
              <Toolbar>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Durations
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Expected time until observing if vaccine worked<span className="policy-number">: {+observeEffectOfVaccineDuration.toFixed(0)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+observeEffectOfVaccineDuration.toFixed(0)}
                        onChange={(_: any, value: any) => setObserveEffectOfVaccineDuration(value)}
                        step={0}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 60, label: '60' },
                        ]}      
                        min={0}
                        max={60}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Expected time until symptoms<span className="policy-number">: {+incubationDuration.toFixed(2)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+incubationDuration.toFixed(2)}
                        onChange={(_: any, value: any) => setIncubationDuration(value)}
                        step={1}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 30, label: '30' },
                        ]}      
                        min={0}
                        max={30}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Expected time until recovery/death without being hospitalised<span className="policy-number">: {+mildDuration.toFixed(0)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+mildDuration.toFixed(0)}
                        onChange={(_: any, value: any) => setMildDuration(value)}
                        step={0}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 60, label: '60' },
                        ]}      
                        min={0}
                        max={60}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Expected time spent hospitalised<span className="policy-number">: {+hospitalisationDuration.toFixed(0)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+hospitalisationDuration.toFixed(0)}
                        onChange={(_: any, value: any) => setHospitalisationDuration(value)}
                        step={1}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 30, label: '30' },
                        ]}      
                        min={0}
                        max={30}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Expected time spent on ICU<span className="policy-number">: {+icuDuration.toFixed(0)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+icuDuration.toFixed(0)}
                        onChange={(_: any, value: any) => setIcuDuration(value)}
                        step={1}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 30, label: '30' },
                        ]}      
                        min={0}
                        max={30}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>
                      Immunity duration after recovery<span className="policy-number">: {+recoveryToImmunityDuration.toFixed(0)}</span>
                    </Typography>
                    <div className={`${classes.slider} policy-slider`}>
                      <Slider
                        track={false}
                        value={+recoveryToImmunityDuration.toFixed(0)}
                        onChange={(_: any, value: any) => setRecoveryToImmunityDuration(value)}
                        step={10}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 5000, label: '5000' },
                        ]}      
                        min={0}
                        max={5000}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
            </>
          )
        }
        <Toolbar className={classes.policyTitle}>
          <Typography className={classes.title} variant="h5">
            Fixed-Time Policies
          </Typography>
        </Toolbar>
        <TableContainer className={`${classes.tableContainer} policy-table`}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell scope="row">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="mutiple-checkbox-label">Policies</InputLabel>
                    <Select
                      labelId="mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={temporaryPolicies}
                      onChange={handleTemporaryPolicyChange}
                      input={<Input />}
                      renderValue={selected => (selected as string[]).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {Array.from(policyMap.keys()).map(policy => (
                        <MenuItem key={policy} value={policy}>
                          <Checkbox checked={temporaryPolicies.includes(policy)} />
                          <ListItemText primary={policy} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  <Typography>
                    Policy day range 
                  </Typography>
                  <div className={classes.sliderPolicy}>
                    <Slider
                      track={false}
                      value={temporaryPoliciesDayRange}
                      onChange={(_: any, value: any) => setTemporaryPoliciesDayRange(value)}
                      step={1}
                      marks={[
                        { value: 0, label: `0` },
                        { value: simulationDuration, label: `${simulationDuration}` },
                      ]}
                      min={0}
                      max={simulationDuration}
                      valueLabelDisplay="auto"
                    />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPolicy}
                    disabled={temporaryPolicies.length === 0}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Policies</TableCell>
                <TableCell align="right">Start day</TableCell>
                <TableCell align="right">End day</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy, index) => (
                <TableRow key={policy.policies.join(', ')}>
                  <TableCell scope="row">
                    {policy.policies.join(', ')}
                  </TableCell>
                  <TableCell align="right">{policy.begin}</TableCell>
                  <TableCell align="right">{policy.end}</TableCell>
                  <TableCell align="right">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setPolicies(policies.filter((_, policyIndex) => policyIndex !== index))}
                      >
                        Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Toolbar className={classes.policyTitle}>
          <Typography className={classes.title} variant="h5">
            Policy Pulse
          </Typography>
        </Toolbar>
        <TableContainer className={`${classes.tableContainer} policy-pulse-table`}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell scope="row">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="mutiple-checkbox-label">Policies</InputLabel>
                    <Select
                      labelId="mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={temporaryPulsePolicies}
                      onChange={handleTemporaryPulsePolicyChange}
                      input={<Input />}
                      renderValue={selected => (selected as string[]).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {Array.from(policyMap.keys()).map(policy => (
                        <MenuItem key={policy} value={policy}>
                          <Checkbox checked={temporaryPulsePolicies.includes(policy)} />
                          <ListItemText primary={policy} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  <Typography>
                    Policy start day
                  </Typography>
                  <div className={classes.sliderPolicy}>
                    <Slider
                      track={false}
                      value={temporaryPulsePoliciesBegin}
                      onChange={(_: any, value: any) => setTemporaryPulsePoliciesBegin(value)}
                      step={1}
                      marks={[
                        { value: 0, label: '0' },
                        { value: simulationDuration, label: `${simulationDuration}` },
                      ]}
                      min={0}
                      max={simulationDuration}
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <Typography>
                    Policy active duration
                  </Typography>
                  <div className={classes.sliderPolicy}>
                    <Slider
                      track={false}
                      value={temporaryPulsePoliciesOnPeriod}
                      onChange={(_: any, value: any) => setTemporaryPulsePoliciesOnPeriod(value)}
                      step={1}
                      marks={[
                        { value: 1, label: '1' },
                        { value: simulationDuration, label: `${simulationDuration}` },
                      ]}
                      min={1}
                      max={simulationDuration}
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <Typography>
                    Policy inactive duration
                  </Typography>
                  <div className={classes.sliderPolicy}>
                    <Slider
                      track={false}
                      value={temporaryPulsePoliciesOffPeriod}
                      onChange={(_: any, value: any) => setTemporaryPulsePoliciesOffPeriod(value)}
                      step={1}
                      marks={[
                        { value: 1, label: '1' },
                        { value: simulationDuration, label: `${simulationDuration}` },
                      ]}
                      min={1}
                      max={simulationDuration}
                      valueLabelDisplay="auto"
                    />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPulsePolicy}
                    disabled={temporaryPulsePolicies.length === 0}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Policies</TableCell>
                <TableCell align="right">Start day</TableCell>
                <TableCell align="right">Active duration</TableCell>
                <TableCell align="right">Inactive duration</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pulsePolicies.map((policy, index) => (
                <TableRow key={policy.policies.join(', ')}>
                  <TableCell scope="row">
                    {policy.policies.join(', ')}
                  </TableCell>
                  <TableCell align="right">{policy.begin}</TableCell>
                  <TableCell align="right">{policy.onPeriod}</TableCell>
                  <TableCell align="right">{policy.offPeriod}</TableCell>
                  <TableCell align="right">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setPulsePolicies(pulsePolicies.filter((_, policyIndex) => policyIndex !== index))}
                      >
                        Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={`${classes.chart} policy-chart`}>
          <Chart data={chartData} >
            <ArgumentAxis showGrid={true} tickFormat={format} />
            <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
            <LineSeries
              name="Confirmed"
              valueField="confirmed"
              argumentField="date"
              color="#003b74"
              seriesComponent={LineWithDotPoint}
            />
            <LineSeries
              name="Active"
              valueField="infected"
              argumentField="date"
              color="#aea96f"
              seriesComponent={LineWithDotPoint}
            />
            <LineSeries
              name="Recovered w/ Immunity"
              valueField="recoveredTemporarily"
              argumentField="date"
              color="#6e7b75"
              seriesComponent={LineWithDotPoint}
            />
            <LineSeries
              name="Recovered w/o Immunity"
              valueField="recoveredWithImmunity"
              argumentField="date"
              color="#3f6294"
              seriesComponent={LineWithDotPoint}
            />
            <LineSeries
              name="Deaths"
              valueField="deaths"
              argumentField="date"
              color="#c3113c"
              seriesComponent={LineWithDotPoint}
            />
            <LineSeries
              name="Critical"
              valueField="critical"
              argumentField="date"
              color="red"
              seriesComponent={LineWithDotPoint}
            />
            <LineSeries
              name="Hospitalised"
              valueField="hospitalised"
              argumentField="date"
              color="#ff8855"
              seriesComponent={LineWithDotPoint}
            />
            <Legend
              position="bottom"
              rootComponent={Root as any}
              itemComponent={Item as any}
              labelComponent={Label as any}
            />
            <Title text="Number of Cases" />
            <EventTracker />
            <Tooltip />
          </Chart>
        </div>
        {
          dataSource && (
            <div className={`${classes.chart} policy-chart`}>
              <Chart data={chartData}>
                <ArgumentAxis showGrid={true} tickFormat={format} />
                <ValueAxis showGrid={true} labelComponent={ValueLabel} />          
                <LineSeries
                  name="Confirmed"
                  valueField="confirmed"
                  argumentField="date"
                  color="#003b74"
                  seriesComponent={LineWithDotPoint}
                />
                <LineSeries
                  name="Real Confirmed"
                  valueField="realConfirmed"
                  argumentField="date"
                  color="#3f6294"
                  seriesComponent={LineWithDotPoint}
                />
                <LineSeries
                  name="Deaths"
                  valueField="deaths"
                  argumentField="date"
                  color="#c3113c"
                  seriesComponent={LineWithDotPoint}
                />
                <LineSeries
                  name="Real Deaths"
                  valueField="realDeaths"
                  argumentField="date"
                  color="red"
                  seriesComponent={LineWithDotPoint}
                />
                <Legend
                  position="bottom"
                  rootComponent={Root as any}
                  itemComponent={Item as any}
                  labelComponent={Label as any}
                />
                <Title text="Simulation vs Observed Data" />
                <EventTracker />
                <Tooltip />
              </Chart>
            </div>
          )
        }
        <div className={`${classes.chart} policy-chart`}>
          <Chart data={chartData}>
            <ArgumentAxis showGrid={true} tickFormat={format} />
            <ValueAxis showGrid={true} labelComponent={ValueLabel} />
            <LineSeries
              name="Reproduction number"
              valueField="reproductionNumber"
              argumentField="date"
              color="#003b74"
              seriesComponent={LineWithDotPoint}
            />
            <Legend
              position="bottom"
              rootComponent={Root as any}
              itemComponent={Item as any}
              labelComponent={Label as any}
            />
            <Title text="Reproduction number" />
            <EventTracker />
            <Tooltip />
          </Chart>
        </div>
      </Paper>
    </div>
  );
}
