import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    box: {
      padding: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    textField: {

    },
    button: {

    },
    input: {

    },
  }),
);

export default function Request() {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const [loading, setLoading] = useState(false);
  const [sentMessage, setSentMessage] = useState('');

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setName(event.target.value);
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setEmail(event.target.value);
  }

  function handleMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setMessage(event.target.value);
  }

  function handleRecaptchaChange(recaptcha: string | null) {
    setRecaptcha(recaptcha || '');
  }

  function handleSubmit() {
    setLoading(true);
    axios.post('/api/processRequest', {
      name,
      message,
      email,
      recaptcha,
    })
    .then(function (response) {
      setLoading(false);
      console.log(response);
      setSentMessage('Request sent successfully.');
      setMessage('');
      setEmail('');
      setName('');
    })
    .catch(function (error) {
      setLoading(false);
      console.log(error);
      setSentMessage('Failed to send request.');
    });
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <Typography className={classes.title} variant="h4">
            Request
          </Typography>
          <Typography paragraph>
            If you are a member of government and require assistance please complete this contact form with your credentials and we will respond.
          </Typography>
          {
            sentMessage && (
              <Typography paragraph>{sentMessage}</Typography>
            )
          }
          <form>
            <TextField
              fullWidth
              id="outlined"
              label="Name"
              className={classes.textField}
              value={name}
              onChange={handleNameChange}
              type="name"
              margin="normal"
              variant="outlined"
              required
              autoFocus
              disabled={loading}
            />
            <TextField
              fullWidth
              id="email"
              label="Email"
              className={classes.textField}
              value={email}
              onChange={handleEmailChange}
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              required
              disabled={loading}
            />
            <TextField
              fullWidth
              id="message"
              label="Message"
              className={classes.textField}
              value={message}
              onChange={handleMessageChange}
              type="text"
              name="message"
              margin="normal"
              variant="outlined"
              required
              multiline
              rows={6}
              disabled={loading}
            />
            <ReCAPTCHA
              sitekey="6LcqV_EUAAAAAMDxgAzCBz-i3doWNy7GN97ScS2P"
              onChange={handleRecaptchaChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!(name && email && message && recaptcha) || loading}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </form>
        </Box>
      </Paper>
    </div>
  );
}
