export enum Policy {
  Lockdown = 'Lockdown',
  LargeEventsBanned = 'Large events banned',
  SchoolsUniversitiesClosed = 'Schools and universities closed',
  GymsAndClubsClosed = 'Gyms and clubs closed',
  RestaurantsAndPubsClosed = 'Restaurants and pubs closed',
  AllNonEssentialBusinessesClosed = 'All other non-essential shops and businesses closed',
  PhysicalDistancing = 'Physical distancing',
  MandatoryMasks = 'Masks mandatory in public',
  ContactTracing = 'Contact tracing',
}

export const policyMap = new Map([
  [Policy.Lockdown, [0.09, 0.17, 0.36]],
  [Policy.LargeEventsBanned, [0.83, 0.915, 0.877]],
  [Policy.SchoolsUniversitiesClosed, [0.544, 0.973, 1]],
  [Policy.GymsAndClubsClosed, [0.83, 0.878, 0.877]],
  [Policy.RestaurantsAndPubsClosed, [0.93, 0.915, 0.803]],
  [Policy.AllNonEssentialBusinessesClosed, [0.965, 0.866, 0.877]],
  [Policy.PhysicalDistancing, [0.81, 0.816, 0.856]],
  [Policy.MandatoryMasks, [0.9, 0.9, 0.9]],
  [Policy.ContactTracing, [0.75, 0.75, 0.75]],
]);

export function policyValues(policies: Policy[]) {
  const values = policies.map(policy => policyMap.get(policy)!);

  return values.reduce(
    (means, value) => {
      return means.map((mean, index) => mean * value[index]);
    },
    [1, 1, 1],
  );
}

export default {
  Policy,
  policyMap,
  policyValues,
};
