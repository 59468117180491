const population = {
  "USA": 330779957,
  "Russia": 145927463,
  "Spain": 46752753,
  "Brazil": 212385054,
  "United Kingdom": 67845213,
  "Italy": 60471682,
  "France": 65257220,
  "Germany": 83753585,
  "Turkey": 84232553,
  "Iran": 83865579,
  "India": 1378418813,
  "Peru": 32917154,
  "Canada": 37703672,
  "Saudi Arabia": 34748812,
  "Belgium": 11583877,
  "Mexico": 128773952,
  "Chile": 19097135,
  "Pakistan": 220368526,
  "Netherlands": 17130596,
  "Qatar": 2875180,
  "Ecuador": 17610964,
  "Belarus": 9449669,
  "Sweden": 10092027,
  "Switzerland": 8647311,
  "Portugal": 10199983,
  "Singapore": 5845014,
  "Bangladesh": 164497521,
  "United Arab Emirates": 9876291,
  "Ireland": 4931300,
  "Poland": 37851214,
  "Ukraine": 43762271,
  "Indonesia": 273184442,
  "Romania": 19251572,
  "South Africa": 59220176,
  "Colombia": 50819268,
  "Kuwait": 4263020,
  "Israel": 8639287,
  "Japan": 126519062,
  "Austria": 9000506,
  "Egypt": 102099285,
  "Dominican Republic": 10835186,
  "Philippines": 109407875,
  "Denmark": 5789887,
  "South Korea": 51264240,
  "Serbia": 8741226,
  "Panama": 4306610,
  "Argentina": 45147454,
  "Czechia": 10706744,
  "Norway": 5416333,
  "Afghanistan": 38819750,
  "Bahrain": 1693829,
  "Algeria": 43754940,
  "Australia": 25465024,
  "Morocco": 36858953,
  "Malaysia": 32316866,
  "Kazakhstan": 18750200,
  "Nigeria": 205500163,
  "Finland": 5539752,
  "Moldova": 4034998,
  "Ghana": 30993199,
  "Oman": 5090324,
  "Armenia": 2962619,
  "Bolivia": 11654083,
  "Luxembourg": 624752,
  "Iraq": 40110732,
  "Hungary": 9663057,
  "Cameroon": 26463124,
  "Azerbaijan": 10128537,
  "Thailand": 69780195,
  "Honduras": 9885675,
  "Uzbekistan": 33411286,
  "Guinea": 13087736,
  "Greece": 10428608,
  "Sudan": 43721964,
  "Senegal": 16688310,
  "Bosnia and Herzegovina": 3283031,
  "Bulgaria": 6954080,
  "Croatia": 4108012,
  "Ivory Coast": 26296533,
  "Guatemala": 17875264,
  "Cuba": 11327386,
  "North Macedonia": 2083384,
  "Iceland": 340988,
  "Estonia": 1326435,
  "DR Congo": 89211817,
  "Djibouti": 986284,
  "Lithuania": 2726257,
  "El Salvador": 6482464,
  "New Zealand": 4817692,
  "Somalia": 15836937,
  "Gabon": 2219197,
  "Slovakia": 5459346,
  "Slovenia": 2078906,
  "Mayotte": 271994,
  "Kyrgyzstan": 6511224,
  "Maldives": 539391,
  "Hong Kong": 7489929,
  "Tunisia": 11804125,
  "Guinea-Bissau": 1962210,
  "Sri Lanka": 21403028,
  "Latvia": 1888407,
  "Albania": 2878146,
  "Kenya": 53624913,
  "Lebanon": 6828786,
  "Cyprus": 1206344,
  "Niger": 24091143,
  "Mali": 20176487,
  "Costa Rica": 5088699,
  "Paraguay": 7122183,
  "Equatorial Guinea": 1397001,
  "Burkina Faso": 20830672,
  "Zambia": 18318568,
  "Andorra": 77251,
  "Venezuela": 28444815,
  "Uruguay": 3472364,
  "Georgia": 3990014,
  "Diamond Princess": 3711,
  "San Marino": 33923,
  "Jordan": 10191291,
  "Haiti": 11386106,
  "Malta": 441410,
  "Channel Islands": 173676,
  "Chad": 16365816,
  "Sierra Leone": 7957089,
  "Jamaica": 2959694,
  "Tanzania": 59517667,
  "Réunion": 894575,
  "Taiwan": 23811922,
  "Republic of the Congo": 5501102,
  "Nepal": 29073225,
  "West Bank and Gaza": 5086676,
  "Ethiopia": 114607221,
  "Central African Republic": 4819607,
  "Togo": 8254589,
  "Isle of Man": 84982,
  "Cabo Verde": 555278,
  "Mauritius": 1271531,
  "Madagascar": 27601550,
  "Montenegro": 628057,
  "Vietnam": 97236622,
  "Rwanda": 12912035,
  "Uganda": 45554546,
  "Nicaragua": 6615255,
  "Liberia": 5042890,
  "French Guiana": 297708,
  "Eswatini": 1158757,
  "Myanmar": 54367787,
  "Martinique": 375297,
  "Faroe Islands": 48842,
  "Guadeloupe": 400116,
  "Gibraltar": 33692,
  "Mozambique": 31144220,
  "Brunei": 436990,
  "Mongolia": 3271940,
  "Mauritania": 4634257,
  "Benin": 12083204,
  "Guyana": 786119,
  "Bermuda": 62303,
  "Cambodia": 16691420,
  "Trinidad and Tobago": 1398974,
  "Cayman Islands": 65631,
  "Aruba": 106714,
  "Monaco": 39210,
  "Bahamas": 392805,
  "Barbados": 287335,
  "Liechtenstein": 38116,
  "Sint Maarten": 42819,
  "Malawi": 19067788,
  "Libya": 6860181,
  "French Polynesia": 280722,
  "Syria": 17447571,
  "Angola": 32734590,
  "Zimbabwe": 14837082,
  "Macau": 648282,
  "Burundi": 11845443,
  "Saint Martin": 38586,
  "Eritrea": 3540578,
  "Antigua and Barbuda": 97835,
  "Botswana": 2345808,
  "Gambia": 2408042,
  "Timor-Leste": 1315383,
  "Grenada": 112464,
  "Bhutan": 770610,
  "Laos": 7262953,
  "Belize": 396752,
  "Fiji": 895695,
  "New Caledonia": 285178,
  "Saint Lucia": 183531,
  "Saint Vincent and the Grenadines": 110900,
  "Curaçao": 164017,
  "Dominica": 71966,
  "Namibia": 2535319,
  "Saint Kitts and Nevis": 53156,
  "Falkland Islands": 3467,
  "Turks and Caicos": 38655,
  "Holy See": 801,
  "Montserrat": 4992,
  "Suriname": 586020,
  "Greenland": 56759,
  "Seychelles": 98277,
  "British Virgin Islands": 30208,
  "Papua New Guinea": 8926371,
  "Caribbean Netherlands": 26195,
  "Saint Barthelemy": 9874,
  "Anguilla": 14987,
  "China": 1439323776,
  "Kosovo": 1845000,
};

export default function getPopulation(country: string) {
  return population[country as keyof typeof population] || 1;
}
