import * as odex from './odex';
import { Policy, policyValues } from './policies';

export default function Model({
  initialPopulation,
  initialExposed,
  initialInfected,
  icuCapacity,
  reproductionNumberEstimate,
  youngPercentage,
  adultPercentage,
  temporaryImmunityFromVaccineProbability,
  asyntomaticProbability,
  hospitalisationProbability,
  directDeathProbability,
  temporaryImmunityFromHospitalisationProbability,
  temporaryImmunityFromIcuProbability,
  immunityProbability,
  seasonalityFactor,
  observeEffectOfVaccineDuration,
  incubationDuration,
  mildDuration,
  hospitalisationDuration,
  icuDuration,
  recoveryToImmunityDuration,
  policies,
  policyTimes,
  duration,
}: {
  initialPopulation: number;
  initialExposed: number;
  initialInfected: number;
  icuCapacity: number;
  reproductionNumberEstimate: number;
  youngPercentage: number;
  adultPercentage: number;
  temporaryImmunityFromVaccineProbability: number;
  asyntomaticProbability: number;
  hospitalisationProbability: number;
  directDeathProbability: number;
  temporaryImmunityFromHospitalisationProbability: number;
  temporaryImmunityFromIcuProbability: number;
  immunityProbability: number;
  seasonalityFactor: number;
  observeEffectOfVaccineDuration: number;
  incubationDuration: number;
  mildDuration: number;
  hospitalisationDuration: number;
  icuDuration: number;
  recoveryToImmunityDuration: number;
  policies: Policy[][];
  policyTimes: { begin: number; end: number; }[];
  duration: number;
}) {
  // 1.PARAMETERS

  const elderPercentage = 1 - youngPercentage - adultPercentage;

  const youngFractionContribution = 351.32; // contribution to the overall infectivity of the younger population
  const adultFractionContribution = 294.3;
  const elderFractionContribution = 162.84;

  const totalFractionContribution = youngFractionContribution * youngPercentage + adultFractionContribution * adultPercentage + elderFractionContribution * elderPercentage;

  const youngContribution = youngFractionContribution * youngPercentage / totalFractionContribution;
  const adultContribution = adultFractionContribution * adultPercentage / totalFractionContribution;
  const elderContribution = elderFractionContribution * elderPercentage / totalFractionContribution;

  const asymptomaticInfectivity = 1; // relative infectivity of asymptomatic
  const hospitalisedInfectivity = 0.05 * adultPercentage; // relative infectivity of the hospital compartment
  const icuInfectivity = 0.05 * adultPercentage; // relative infectivity of the critical compartment

  const temporaryImmunityProbability = 1 - hospitalisationProbability - directDeathProbability;

  // 1.2.2 Durations = weighted averages of probabilities of each outcome and the time spend in this compartment, otherwise would need a set of DDEs, not ODEs
  const alpha_v = 1 / observeEffectOfVaccineDuration; // 1 / expected time till observing whether a vaccine worked
  const alpha_e = 1 / incubationDuration;  // 1 / incubation period
  const alpha_i = 1 / mildDuration; // 1 / expected time until recovery, hospitalization or death without going to the hospital
  const alpha_h = 1 / hospitalisationDuration; // 1 / expected time spent in a hospital
  const alpha_c = 1 / icuDuration; // 1 / expected time spent in the ICU 
  const alpha_rdot = 1 / recoveryToImmunityDuration; // 1 / expected time to permanent immunity

  const numberOfDailyVaccines = 0;

  // 2. Set up simulations
  function seasonFactor(t: number) {
    return seasonalityFactor === 1
      ? 0.5 + 0.5 * Math.abs(Math.cos(t * Math.PI / 365))
      : 1;
  }

  function dotProduct(x: number[], y: number[]) {
    return x.map((value, index) => value * y[index]).reduce((sum, value) => sum + value, 0);
  }

  // 2.3 Equations for disease spread
  function deriv(y: number[], t: number, reduction: number[][], beta: number) {
    const [S, V, E, I, H, C, Rdot, , N] = y;

    const policyIndex = policyTimes.findIndex(({ begin, end }) => begin <= t && t <= end);

    if (policyIndex >= 0) {
      if (C < icuCapacity) {
        return [
          -beta / N * S * (dotProduct([E * asymptomaticInfectivity, I], reduction[policyIndex]) + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) + (1 - immunityProbability) * alpha_rdot * Rdot + (1 - temporaryImmunityFromVaccineProbability) * alpha_v * V - Math.min(S, numberOfDailyVaccines),
          Math.min(S, numberOfDailyVaccines) - alpha_v * V,
          beta / N * S * (dotProduct([E*asymptomaticInfectivity,I], reduction[policyIndex]) + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) - alpha_e * E,
          (1 - asyntomaticProbability) * alpha_e * E - alpha_i * I,
          hospitalisationProbability * alpha_i * I - alpha_h * H,
          (1 - temporaryImmunityFromHospitalisationProbability) * alpha_h * H - alpha_c * C,
          asyntomaticProbability * alpha_e * E + temporaryImmunityFromVaccineProbability * alpha_v * V + temporaryImmunityProbability * alpha_i * I + temporaryImmunityFromHospitalisationProbability * alpha_h * H + temporaryImmunityFromIcuProbability * alpha_c * C - alpha_rdot * Rdot,
          immunityProbability * alpha_rdot * Rdot,
          -(1 - temporaryImmunityFromIcuProbability) * alpha_c * C - directDeathProbability * alpha_i * I,
        ];
      } else {
        return [
          -beta / N * S * (dotProduct([E * asymptomaticInfectivity, I], reduction[policyIndex]) + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) + (1 - immunityProbability) * alpha_rdot * Rdot + (1 - temporaryImmunityFromVaccineProbability) * alpha_v * V - Math.min(S, numberOfDailyVaccines),
          Math.min(S, numberOfDailyVaccines) - alpha_v * V,
          beta / N * S * (dotProduct([E * asymptomaticInfectivity, I], reduction[policyIndex]) + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) - alpha_e * E,
          (1 - asyntomaticProbability) * alpha_e * E - alpha_i * I,
          hospitalisationProbability * alpha_i * I - alpha_h * H,
          (1 - temporaryImmunityFromHospitalisationProbability) * alpha_h * H - alpha_c * C,
          asyntomaticProbability * alpha_e * E + temporaryImmunityFromVaccineProbability * alpha_v * V + temporaryImmunityProbability * alpha_i * I + temporaryImmunityFromHospitalisationProbability * alpha_h * H - alpha_rdot * Rdot,
          immunityProbability * alpha_rdot * Rdot,
          -alpha_c * C - directDeathProbability * alpha_i * I,
        ];
      }
    } else {
      if (C < icuCapacity) {
        return [
          -beta / N * S * (E * asymptomaticInfectivity + I + H * hospitalisedInfectivity + C * icuInfectivity) *seasonFactor(t) + (1 - immunityProbability) * alpha_rdot * Rdot + (1 - temporaryImmunityFromVaccineProbability) * alpha_v * V - Math.min(S, numberOfDailyVaccines),
          Math.min(S, numberOfDailyVaccines) - alpha_v * V,  
          beta / N * S * (E * asymptomaticInfectivity + I + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) - alpha_e * E,
          (1 - asyntomaticProbability) * alpha_e * E - alpha_i * I,
          hospitalisationProbability * alpha_i * I - alpha_h * H,
          (1 - temporaryImmunityFromHospitalisationProbability) * alpha_h * H - alpha_c * C,
          asyntomaticProbability * alpha_e * E + temporaryImmunityFromVaccineProbability * alpha_v * V + temporaryImmunityProbability * alpha_i * I + temporaryImmunityFromHospitalisationProbability * alpha_h * H + temporaryImmunityFromIcuProbability * alpha_c * C - alpha_rdot * Rdot,
          immunityProbability * alpha_rdot * Rdot,
          -(1 - temporaryImmunityFromIcuProbability) * alpha_c * C - directDeathProbability * alpha_i * I,
        ];
      } else {
        return [
          -beta / N * S * (E * asymptomaticInfectivity + I + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) + (1 - immunityProbability) * alpha_rdot * Rdot + (1 - temporaryImmunityFromVaccineProbability) * alpha_v * V - Math.min(S, numberOfDailyVaccines),
          Math.min(S, numberOfDailyVaccines) - alpha_v * V,
          beta / N * S * (E * asymptomaticInfectivity + I + H * hospitalisedInfectivity + C * icuInfectivity) * seasonFactor(t) - alpha_e * E,
          (1 - asyntomaticProbability) * alpha_e * E - alpha_i*  I,
          hospitalisationProbability * alpha_i * I - alpha_h * H,
          (1 - temporaryImmunityFromHospitalisationProbability) * alpha_h * H - alpha_c * C,
          asyntomaticProbability * alpha_e * E + temporaryImmunityFromVaccineProbability * alpha_v * V + temporaryImmunityProbability * alpha_i * I+temporaryImmunityFromHospitalisationProbability*alpha_h*H-alpha_rdot*Rdot,
          immunityProbability * alpha_rdot * Rdot,
          -alpha_c * C - directDeathProbability * alpha_i * I,
        ];
      }
    }
  }

  // 2.5 Running coupling R_c
  // Initial terms
  const E_term = asymptomaticInfectivity / alpha_e; // exposed term
  const EI_term = asymptomaticInfectivity * (1 - asyntomaticProbability) / alpha_i; // exposed to infected term
  const EIH_term = asymptomaticInfectivity * hospitalisedInfectivity * (1 - asyntomaticProbability) * hospitalisationProbability / alpha_h; // exposed to infected to hospitalised term
  const EIHC_term = asymptomaticInfectivity * hospitalisedInfectivity * icuInfectivity * (1 - asyntomaticProbability) * hospitalisationProbability * (1 - temporaryImmunityFromHospitalisationProbability) / alpha_c; // exposed to infected to hospitalised to critical care term

  // Running coupling / contact term as a function of policies
  function termsSum(t: number, reduction: number[][]) {
    const policyIndex = policyTimes.findIndex(({ begin, end }) => begin <= t && t <= end);

    if (policyIndex >= 0) {
      return dotProduct([E_term, EI_term], reduction[policyIndex]) + EIH_term + EIHC_term;
    }
    
    return E_term + EI_term + EIH_term + EIHC_term;
  }

  // 3. SIMULATIONS
  // 3.1 Set up simulation parameters
  const [V0, E0, I0, H0, C0, Rdot0, R0] = [0, initialExposed, initialInfected, 0, 0, 0, 0]; // initial number
  const S0 = initialPopulation - V0 - E0 - I0 - H0 - C0 - Rdot0 - R0; // Everyone else, S0, is susceptible to infection initially.
  const y0 = [S0, V0, E0, I0, H0, C0, Rdot0, R0, initialPopulation]; // initial conditions
  const basicReproductionNumber = reproductionNumberEstimate; // basic reproduction number
  const beta = basicReproductionNumber / (E_term + EI_term + EIH_term + EIHC_term); // contact term

  // 3.2 Set up all the data
  const policyCoefficients = policies.map(policy => {
    const [youngValue, adultValue, elderValue] = policyValues(policy);

    const total = youngContribution * youngValue + adultContribution * adultValue + elderContribution * elderValue;

    return [total, total];
  });

  // 3.2 Predictions for the future
  // 3.2.1 Time evolution of disease spread
  const solver = new odex.Solver(9);
  solver.denseOutput = true;

  const results = {
    t: [] as number[],
    y: [] as number[][],
  };

  solver.solve(
    (t: number, y: number[]) => deriv(y, t, policyCoefficients, beta),
    0,
    y0,
    duration,
    solver.grid(1, (t: number,y: number[]) => {
      results.t.push(t);
      results.y.push(y);
    }),
  );

  results.y
    .map((_, index, array) => index < array.length - 18
      ? initialPopulation - results.y[index + 18][8]
      : initialPopulation - results.y[array.length - 1][8])
    .forEach((deaths, index) => {
      results.y[index].push(deaths);
    });

  // 3.2.2 Time evolution of the basic reproduction number
  results.y
    .map((_, index) => beta * termsSum(index, policyCoefficients) * results.y[index][0] / results.y[index][8])
    .forEach((reproductionNumber, index) => {
      results.y[index].push(reproductionNumber);
    });

  return results.t.map((date, index) => {
    const [
      susceptible,
      vaccinated,
      exposed,
      infected,
      hospitalised,
      critical,
      recoveredTemporarily,
      recoveredWithImmunity,
      population,
      deaths,
      reproductionNumber,
    ] = results.y[index];

    return {
      date,
      susceptible: Math.round(susceptible),
      vaccinated: Math.round(vaccinated),
      exposed: Math.round(exposed),
      infected: Math.round(infected),
      hospitalised: Math.round(hospitalised),
      critical: Math.round(critical),
      recoveredTemporarily: Math.round(recoveredTemporarily),
      recoveredWithImmunity: Math.round(recoveredWithImmunity),
      population: Math.round(population),
      deaths: Math.round(deaths),
      reproductionNumber: +(reproductionNumber.toFixed(2)),
      confirmed: Math.round(initialPopulation - susceptible),
    };
  });
}
