
import React, { useCallback, useState, ChangeEvent } from 'react';
import { fade, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import ValueLabelComponent from './ValueLabelComponent';
import 'leaflet';
import { CircleMarker, Map as LeafletMap, TileLayer } from 'react-leaflet';

interface WorldMapProps {
  markers?: {
    nationRegion: string;
    latitude: number;
    longitude: number;
    radius: number;
    color: string;
  }[];
  scale: number;
}

function WorldMap({ markers = [], scale }: WorldMapProps) {
  const [latlng, setLatlng] = useState({ lat: 53.483959, lng: -2.244644 });

  const handleClick = useCallback((event: { latlng: { lat: number; lng: number; } }) => {
    setLatlng(event.latlng);
  }, []);

  return (
    <LeafletMap
      center={latlng}
      length={4}
      style={{ height: 'calc(100vh - 256px)', maxWidth: '100vw' }}
      onClick={handleClick}
      zoom={5}
      minZoom={4}
      maxZoom={6}
    >
      <TileLayer
        url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}"
        attribution={'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
        subdomains="abcd"
        minZoom={2}
        maxZoom={6}
        noWrap={true}
        ext="png"
      />
      {
        markers.map(marker => marker.radius === 0 ? null : (
          <CircleMarker
            key={marker.nationRegion}
            center={{ lat: marker.latitude, lng: marker.longitude }}
            radius={marker.radius === 1 ? 1 : 50 * Math.log(marker.radius) / Math.log(scale)}
            color={marker.color}
          />
        ))
      }
    </LeafletMap>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    title: {
      flex: '1 1 100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    slider: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      paddingRight: 40,
      paddingLeft: 20,
      width: '100%',
    },
  }),
);

enum Option {
  Confirmed = 'confirmed',
  Active = 'active',
  Recovered = 'recovered',
  Deaths = 'deaths',
}

interface Props {
  data: {
    nationRegion: string;
    latitude: number;
    longitude: number;
    data: {
      date: string;
      confirmed: number;
      deaths: number;
      recovered: number;
    }[];
  }[];
}

export default function Map({ data }: Props) {
  const classes = useStyles();
  const [option, setOption] = useState(Option.Confirmed);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOption((event.target as HTMLInputElement).value as Option);
  };

  let multiplier = 1;

  const dateSet = new Set<string>();

  data.forEach(nationRegionData => nationRegionData.data.forEach(day => dateSet.add(day.date)));

  const dateList = Array.from(dateSet).sort();

  const [dateIndex, setDate] = useState(dateList.length - 1);
  const date = dateList[dateIndex];

  let color = 'blue';

  switch (option) {
    case Option.Confirmed:
      color = '#003b74';
      break;
    case Option.Active:
      color = '#aea96f';
      break;
    case Option.Recovered:
      color = '#6e7b75';
      break;
    case Option.Deaths:
      color = '#c3113c';
      break;
  }

  const markers = data.map(nationRegionData => {
    const selectedDayIndex = nationRegionData.data.findIndex(day => day.date === date);
    
    if (selectedDayIndex === -1) {
      return {
        nationRegion: nationRegionData.nationRegion,
        latitude: nationRegionData.latitude,
        longitude: nationRegionData.longitude,
        radius: 0,
        color,
      };
    }

    const today = nationRegionData.data[selectedDayIndex];

    let value = 0;

    switch (option) {
      case Option.Confirmed:
        value = today.confirmed;
        break;
      case Option.Active:
        value = today.confirmed - today.recovered - today.deaths;
        break;
      case Option.Recovered:
        value = today.recovered;
        break;
      case Option.Deaths:
        value = today.deaths;
        break;
    }

    multiplier = Math.max(multiplier, value);

    return {
      nationRegion: nationRegionData.nationRegion,
      latitude: nationRegionData.latitude,
      longitude: nationRegionData.longitude,
      radius: value,
      color,
    };
  });

  const handleChangeSlider = (_: unknown, value: number | number[]) => {
    setDate(value as number);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} >
          <Typography className={classes.title} variant="h4" id="tableTitle">
            UK COVID-19 Map
          </Typography>
          <div className={classes.slider}>
            <Slider
              ValueLabelComponent={ValueLabelComponent}
              value={dateIndex}
              valueLabelFormat={(value: number) => dateList[value]}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={[
                { value: 0, label: dateList[0] },
                { value: dateList.length - 1, label: dateList[dateList.length - 1] },
              ]}
              min={0}
              max={dateList.length - 1}
              onChange={handleChangeSlider}
            />
          </div>
        </Toolbar>
        <Toolbar>
          <RadioGroup
            row={true}
            value={option}
            onChange={handleChange}
          >
            <FormControlLabel
              value={Option.Confirmed}
              control={<Radio />}
              label="Confirmed cases"
            />
            <FormControlLabel
              value={Option.Active}
              control={<Radio />}
              label="Active cases"
            />
            <FormControlLabel
              value={Option.Recovered}
              control={<Radio />}
              label="Recovered cases"
            />
            <FormControlLabel
              value={Option.Deaths}
              control={<Radio />}
              label="Deaths"
            />
          </RadioGroup>
        </Toolbar>
        <WorldMap markers={markers} scale={multiplier} />
      </Paper>
    </div>
  );
}
