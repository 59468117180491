enum Path {
  Map = 'world-map',
  Table = 'country-table',
  Timeline = 'world-timeline',
  Country = 'country-timeline',
  CountryComparison = 'country-comparison',
  MapUsa = 'us-map',
  TableUsa = 'us-state-table',
  State = 'us-state-timeline',
  MapUk = 'uk-map',
  TableUk = 'uk-table',
  TimelineUk = 'uk-timeline',
  Calculator = 'policy-manager',
  CalculatorUk = 'uk-policy-manager',
  CalculatorUsa = 'us-policy-manager',
  KeyFindings = 'key-findings',
  About = 'about',
  Methodology = 'methodology',
  Request = 'request',
}

export default Path;
