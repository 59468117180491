const population = {
  "Wales": 3136000,
  "Scotland": 5454000,
  "Northern Ireland": 1885000,
  "Yorkshire and The Humber": 5486000,
  "West Midlands": 2928000,
  "South West": 5616000,
  "South East": 9175000,
  "North West": 7300000,
  "North East": 2657000,
  "London": 8982000,
  "East of England": 6235000,
  "East Midlands": 4811000,
}

export default function getUkPopulation(nationRegion: string) {
  return population[nationRegion as keyof typeof population] || 1;
}
